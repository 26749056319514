import React from 'react'

interface IProps {
  color?: string
}

/* eslint-disable max-len */
const LogoIcon = ({ color = '#BF07FF' }: IProps) => {
  return (
    <svg
      width='20'
      height='25'
      viewBox='0 0 20 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7944 8.53576C16.6345 8.38041 16.4102 8.30982 16.1908 8.34591C15.9709 8.38187 15.781 8.51991 15.679 8.71801C15.3282 9.39952 14.8826 10.0258 14.3583 10.5795C14.4106 10.1795 14.4369 9.7772 14.4369 9.37379C14.4369 8.59974 14.3332 7.80319 14.1284 7.00599C13.4554 4.3875 11.6906 2.17307 9.28658 0.930549C9.07728 0.822408 8.8279 0.826205 8.62198 0.940674C8.41606 1.05519 8.2812 1.26514 8.26273 1.49999C8.07532 3.87956 6.84963 6.04444 4.89808 7.44094C4.87225 7.45955 4.84661 7.47839 4.82097 7.49714C4.76786 7.536 4.7178 7.57275 4.67102 7.6036C4.6637 7.60847 4.65644 7.61344 4.64931 7.61855C3.42194 8.49732 2.4092 9.66873 1.72046 11.0065C1.02062 12.3672 0.665771 13.8274 0.665771 15.3464C0.665771 16.1202 0.769506 16.9168 0.974116 17.7141C2.05388 21.9168 5.83596 24.8521 10.1714 24.8521C15.4126 24.8521 19.6765 20.5878 19.6765 15.3464C19.6765 12.7617 18.653 10.343 16.7944 8.53576Z'
        fill={color}
      />
    </svg>
  )
}
/* eslint-enable max-len */

export default LogoIcon
