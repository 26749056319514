import React from 'react'

interface IProps {
  color?: string
}

/* eslint-disable max-len */
const DownloadIcon = ({ color = '#fff' }: IProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
    >
      <path
        fill={color}
        d='M17.648 6.458 13.69 2.895c-1.127-1.015-1.691-1.522-2.383-1.788l-.01 2.735c0 2.357 0 3.535.733 4.267.732.733 1.91.733 4.267.733h3.58c-.362-.705-1.011-1.289-2.228-2.384Z'/>
      <path
        fill={color}
        fillRule='evenodd'
        d='M8.296 20.842h4c3.772 0 5.657 0 6.829-1.172 1.171-1.172 1.171-3.057 1.171-6.828v-.438c0-.872 0-1.528-.042-2.062H16.2c-1.097 0-2.066 0-2.848-.105-.847-.114-1.694-.376-2.385-1.067-.691-.691-.953-1.538-1.067-2.385-.105-.782-.105-1.751-.105-2.848l.01-2.835c0-.082.007-.164.02-.244-.408-.016-.894-.016-1.5-.016-3.79 0-5.686 0-6.858 1.171C.296 3.185.296 5.07.296 8.842v4c0 3.77 0 5.656 1.172 6.828 1.172 1.172 3.057 1.172 6.828 1.172Zm-2.013-2.953a.75.75 0 0 0 1.026 0l2-1.875a.75.75 0 1 0-1.026-1.095l-.737.691v-3.268a.75.75 0 1 0-1.5 0v3.268l-.737-.69a.75.75 0 1 0-1.026 1.094l2 1.875Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
/* eslint-enable max-len */

export default DownloadIcon
