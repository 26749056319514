import React from 'react'

/* eslint-disable max-len */
const SaveIcon = () => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5332 10.8208C20.5332 15.5348 20.5332 17.8919 19.0687 19.3563C17.6043 20.8208 15.2472 20.8208 10.5332 20.8208C5.81916 20.8208 3.46214 20.8208 1.99767 19.3563C0.533203 17.8919 0.533203 15.5348 0.533203 10.8208C0.533203 6.10676 0.533203 3.74973 1.99767 2.28527C3.46213 0.820801 5.81916 0.820801 10.5332 0.820801C15.2472 0.820801 17.6043 0.820801 19.0687 2.28527C20.5332 3.74973 20.5332 6.10676 20.5332 10.8208ZM15.9877 5.72423C16.3172 5.97526 16.3808 6.44585 16.1298 6.77533L9.27263 15.7753C9.13609 15.9545 8.92622 16.0629 8.70104 16.0704C8.47586 16.0779 8.25924 15.9838 8.11107 15.814L4.96822 12.214C4.6958 11.902 4.72793 11.4282 5.03996 11.1558C5.35199 10.8834 5.82578 10.9155 6.09819 11.2276L8.63655 14.1351L14.9366 5.86627C15.1877 5.53679 15.6583 5.4732 15.9877 5.72423Z'
        fill='white'
      />
    </svg>
  )
}
/* eslint-enable max-len */

export default SaveIcon
