import React from 'react'

interface IProps {
  color?: string
}

/* eslint-disable max-len */
const CloseIcon = ({ color = '#8A94A0' }: IProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 20C5.28595 20 2.92893 20 1.46447 18.5355C0 17.0711 0 14.714 0 10C0 5.28595 0 2.92893 1.46447 1.46447C2.92893 0 5.28595 0 10 0C14.714 0 17.0711 0 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20ZM6.96965 6.96967C7.26254 6.67678 7.73742 6.67678 8.03031 6.96967L9.99999 8.93935L11.9696 6.96969C12.2625 6.6768 12.7374 6.6768 13.0303 6.96969C13.3232 7.26258 13.3232 7.73746 13.0303 8.03035L11.0606 10L13.0303 11.9697C13.3232 12.2625 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2625 13.3232 11.9696 13.0303L9.99999 11.0607L8.03033 13.0303C7.73744 13.3232 7.26256 13.3232 6.96967 13.0303C6.67678 12.7374 6.67678 12.2626 6.96967 11.9697L8.93933 10L6.96965 8.03033C6.67676 7.73744 6.67676 7.26256 6.96965 6.96967Z'
        fill={color}
        fillOpacity='0.5'
      />
    </svg>
  )
}
/* eslint-enable max-len */

export default CloseIcon
